<template>
  <div>
    <b-row v-if="!windows">
      <b-col>
        <div class="custom-well">
          <div>
            <p class="content-title">Connect To</p>
            <p class="content-body">13.214.104.30</p>
          </div>
          <p class="content-title">Username</p>
          <p class="content-body">admin</p>
          <div></div>
          <p class="content-title">Password</p>

          <p class="content-note">
            This instance uses your default (ap-southeast-1) key pair to sign
          </p>

          <p class="content-note">
            in. You can download your default private key from the SSH Keys
            page.
          </p>
        </div>
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col>
        <div class="custom-well">
          <div>
            <p class="content-title">Connect To</p>
            <p class="content-body">13.214.104.30</p>
          </div>
          <p class="content-title">Username</p>
          <p class="content-body">admin</p>
          <div></div>
          <p class="content-title">Password</p>

          <p class="content-note">
            <span v-b-modal.modal-password>Retrieve password</span>
          </p>

          <p class="content-note">
            in. You can download your default private key from the SSH Keys
            page.
          </p>
        </div>
      </b-col>

      <BaseModal
        id="modal-password"
        modalTitle="Default Password"
        okTitle="Baik, saya paham."
        :cancelDisabled="true"
        :hide-footer="true"
        ok-only
      >
        <template v-slot:modal-content>
          <b-row>
            <b-col>
              <p class="modal-content-top">
                The default password for this instance only is:
              </p>
              <p class="modal-content-mid">EcVLayj%;jqyHuTCrdW%Zp%t?fhH52OR</p>
              <p class="modal-content-bot">
                Your instance is assigned a default password at creation. If you
                change the password for your instance in Windows, this password
                no longer works.
              </p>
            </b-col>
          </b-row>

          <b-row class="mt-3">
            <b-col sm="4"></b-col>
            <b-col sm="4">
              <BaseButton
                name="Baik, saya paham !"
                variant="info"
                :block="true"
                @click="hideModal"
              />
            </b-col>
            <b-col sm="4"></b-col>
          </b-row>
        </template>
      </BaseModal>
    </b-row>
  </div>
</template>

<script>
import BaseModal from "@/views/pages/product/lightsail/components/BaseModal.vue";
import BaseButton from "@/views/pages/product/lightsail/components/BaseButton.vue";

export default {
  components: {
    BaseModal,
    BaseButton
  },
  data() {
    return {
      keyName: "",
      windows: true
    };
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-password");
    }
  }
};
</script>

<style scoped>
.custom-well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  border-radius: 10px;
}

p.content-title {
  font-size: large;
}

p.content-body {
  font-size: xx-large;
  margin-top: -8px;
  margin-bottom: 15px;
  color: #000000;
  font-weight: 400;
  opacity: 0.8;
}

p.content-note {
  margin-top: 8px;
}

.modal-content-top {
  font-size: large;
  text-align: center;
  padding-bottom: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.modal-content-mid {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  padding-bottom: 30px;
}

.modal-content-bot {
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.custom-modal-footer {
  padding: 15px;
  text-align: center;
}
</style>
